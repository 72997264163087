import singletonLocalStorage from "@/utils/localStoring";
import { getUserSingleton } from "../user/user.store";

export const verticalMiddleware = (store) => (next) => (action) => {
    if (action.type === "selectedVertical/setSelectedVertical") {
        const currUser = getUserSingleton();
        try {
            const verticals =
                currUser.company.active_subscription.verticals.map(
                    (vertical) => vertical.id
                );
            if (verticals.includes(action.payload)) {
                singletonLocalStorage.setItem("vertical", action.payload);
            }
        } catch (error) {
            /* empty */
        }
    }
    return next(action);
};
