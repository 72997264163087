// userStore.js
let currentUser = null;

export const setUserSingleton = (user) => {
    currentUser = user;
};

export const getUserSingleton = () => {
    return currentUser;
};
