import { getAge } from "@/utils/utilFunctions";
import { Mixpanel, captureEvent } from "../Mixpanel";
import {
    prefix_to_object,
    parse_query_params,
    free_trial_props,
    getSubscriptionDetails,
    getActiveVertical,
} from "../utils";
import i18n from "@/i18n";
import store from "@/core/stores";
import { getUser } from "@/contexts/UserContext";

export class LoginLogOutSignUpTracking {
    state = store.getState();
    constructor(props) {
        this.vertical = this.state.selectedVertical.vertical;
        this.currUser = getUser();
        this.props = props;
    }

    login_complete = (res) => {
        let free_trial_data = {};
        Mixpanel.identify(res.data.id);
        if (res.data.company) {
            free_trial_data = free_trial_props(res.data);
            Mixpanel.register({
                language: i18n.language,
                verified: res.data.verified,
            });
            Mixpanel.people.set({
                name: res.data.name,
                email: res.data.email,
                phone: res.data.phone,
                role: res.data.role,
                position: res.data.position,
                verified: res.data.verified,
                type_of_user: res.data.views,
                company: res.data.company.name,
                company_website: res.data.company.website,
                company_size: res.data.company.size,
                company_email_domain: res.data.company.email_domain,
                company_industry: res.data.company.industry,
                company_location: res.data.company.location,
            });
        } else {
            Mixpanel.register({
                language: i18n.language,
            });
            Mixpanel.people.set({
                name: res.data.name,
                email: res.data.email,
                age: res.data.age || getAge(res.data.date_of_birth),
                phone: res.data.phone,
                gender: res.data.gender || "not-set",
                city: res.data.city.name_en,
                job_type: res.data.job_type || "not-set",
                type_of_user: res.data.views,
                major: res.data.major?.name_en || "not-set",
                has_bachelors: res.data.has_bachelors,
                university: res.data.university?.name_en || "not-set",
                months_of_experience:
                    res.data.months_of_experience || "not-set",
                salary_expectations:
                    res.data.salary_expectations?.name_en || "not-set",
                verified: true,
            });
        }
        captureEvent("Login Completed", {
            From: parse_query_params().source,
            ...free_trial_data,
        });
    };
    employee_added_from_form = () => {
        captureEvent("Employee Added Form Submitted", {
            email: this.props.email,
            ...prefix_to_object(this.props.company, "company_"),
            name: this.props.name,
        });
    };
    logout_complete = () => {
        captureEvent("Logout Completed", {
            ...getSubscriptionDetails(this.currUser),
            ...getActiveVertical(this.vertical),
        });
    };
}
