import {
    getUserOS,
    isOnboarding,
    needsToFillPlacement,
    bootcamperNeedToContinue,
} from "@/utils/utilFunctions";

const onBoardingStyle = (currentUser) => ({
    pointerEvents: isOnboarding(currentUser) ? "none" : "auto",
    filter: isOnboarding(currentUser) ? "blur(5px)" : "none",
    userSelect: isOnboarding(currentUser) ? "none" : "text",
});

export {
    getUserOS,
    isOnboarding,
    needsToFillPlacement,
    bootcamperNeedToContinue,
    onBoardingStyle,
};