import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getSetUser } from "@/contexts/UserContext";
import { getSetLookups } from "@/contexts/LookupsStateContext";
import { Factory, Pages } from "@/mixpanel-tracking/hp/factory";
import { parse_query_params } from "@/mixpanel-tracking/utils";
import singletonLocalStorage from "@/utils/localStoring";
import axiosInstance from "@/../static/axios";
import {
    getLookups,
    needsToFillPlacement,
    bootcamperNeedToContinue,
} from "@/utils/utilFunctions";
import { CurrUserDetails } from "@/utils/currUserType";
import { NotificationItemTracking } from "@/mixpanel-tracking/hp/notification-tracking";

type Props = {
    children: React.ReactNode;
};
const RequireAuthRedirect = ({ children }: Props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [tokenExpired, setTokenExpired] = useState(false);
    const setCurrUser = getSetUser();
    const setLookups = getSetLookups();

    useEffect(() => {
        if (singletonLocalStorage.getItem("access_token") !== null) {
            axiosInstance
                .get("/user/currUser/")
                .then((res) => {
                    const userData = {
                        ...res.data,
                        active_subscription: {
                            ...res.data.active_subscription,
                            verticals: [1, 2],
                        },
                    };
                    getLookups(setLookups, res.data.views !== "hp");
                    setCurrUser(userData);
                    checkAndTriggerNotificationAnalytics(userData);
                    handleRedirection(userData);
                })
                .catch(() => {
                    setTokenExpired(true);
                });
        } else {
            if (
                location.pathname.includes("hp") ||
                location.pathname.includes("cp")
            ) {
                navigate("/");
            }
        }
    }, []);

    const checkAndTriggerNotificationAnalytics = (
        current_user: CurrUserDetails
    ) => {
        const query_params = parse_query_params();
        if (shouldSkipNotificationAnalytics(query_params.source)) {
            return;
        }
        const factory = new Factory(current_user);
        const notification_tracker = factory.get_tracker(
            Pages.NOTIFICATION_ITEM
        ) as NotificationItemTracking;
        const notificationData = {
            trigger_time: parseInt(query_params.trigger_time) * 1000,
        };
        const analyticsData = {
            job_id: query_params.job_id,
            employee_id: query_params.employee_id,
            candidate_id: query_params.candidate_id,
            category: query_params.category,
        };
        notification_tracker.click(
            notificationData,
            query_params.source,
            analyticsData
        );
    };

    const shouldSkipNotificationAnalytics = (source: string) => {
        const validSources = ["whatsApp", "email"];
        return !validSources.includes(source);
    };

    const handleRedirection = (userData: CurrUserDetails) => {
        const from = location.pathname + location.search;
        if (bootcamperNeedToContinue(userData)) {
            navigate("candidate/bt-placement");
        } else {
            if (userData.signup_status === true) {
                navigate("hp/signup-wizard");
            } else if (from) {
                navigate(from);
            } else {
                let link = userData.views;
                switch (userData.views) {
                    case "hp":
                        link += "/candidates";
                        break;
                    case "candidate":
                        link += "/jobs";
                        break;
                    case "fast-tracker":
                        link = "candidate/ft-vetting-process";
                }
                navigate(link, {
                    replace: true,
                    state: { source: "Wrong Page - Redirected here" },
                });
            }
            if (
                from &&
                !from.includes("candidate/complete-placement") &&
                needsToFillPlacement(userData)
            ) {
                document.getElementById("complete-ur-profile")!.style.display =
                    "flex";
            }
        }
    };

    if (tokenExpired) {
        singletonLocalStorage.removeItem("access_token");
        navigate("?reason=token-expiry");
        return null;
    }
    return children;
};

export default RequireAuthRedirect;
