import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import RequireAuth from "@/core/routes/requires/require-auth";
import PublicRoutes from "@/core/routes/PublicRoutes";
import useLanguageChange from "@/hooks/useLanguageChange";
import { Factory, Pages } from "@/mixpanel-tracking/hp/factory";
import { ExtraTracker } from "@/mixpanel-tracking/hp/extra-trackers";
import { gb } from ".";
import AppProviders from "@/core/routes/providers/app.provider";
import { Helmet } from "react-helmet";
import { ErrorBoundary } from "react-error-boundary";
import Error from "@/components/molecules/ErrorPages/Error";
import RequireSubscription from "@/core/routes/requires/require-subscription";
import Layout from "./components/_Layout";
import SalesHPRoutes from "@/core/routes/hiring-partner/sales.routes";
import SalesCPRoutes from "@/core/routes/candidate/sales.routes";
import withBodyClass from "@/hooks/useBodyClass";
import MarketingHPRoutes from "@/core/routes/hiring-partner/marketing.routes";
import ContentLoader from "@/components/molecules/layout/loader/content-loader";
import RequireAuthRedirect from "@/core/routes/requires/require-auth-redirect";
import RequireVerticalSubscription from "@/core/routes/requires/require-vertical-subscription";
import Verticals from "./core/enums/verticals.enum";
import ScalersLoader from "./components/molecules/layout/loader/scalers-loader";
import GlobalRoutes from "./core/routes/hiring-partner/global.routes";
import AccountingRoutes from "./core/routes/hiring-partner/accounting.routes";
import ScalersSnackbar from "@/components/molecules/snackbar/Snackbar";
import { useSelector } from "react-redux";
import { FeatureFlag } from "./feature-flag";

const GeneralSnackbar: React.FC = () => {
    const { message, ...notificationSnackbarProps } = useSelector(
        // eslint-disable-next-line
        // @ts-expect-error
        (state) => state.general.notificationSnackbar
    );

    return (
        <ScalersSnackbar {...notificationSnackbarProps}>
            {message}
        </ScalersSnackbar>
    );
};

const _App: React.FC = () => {
    const factory = new Factory();
    const track = factory.get_tracker(
        Pages.EXTRA_TRACKER
    ) as unknown as ExtraTracker;
    const { locale, t } = useLanguageChange({
        trackLanguageChange: track.language_change,
        loadFeatures: gb.loadFeatures,
    });

    return (
        <AppProviders>
            <Helmet
                htmlAttributes={{
                    lang: locale,
                    dir: locale === "en" ? "ltr" : "rtl",
                }}
            />
            <GeneralSnackbar />
            <div className="container">
                <Router>
                    <ErrorBoundary fallback={<Error />}>
                        <RequireAuthRedirect>
                            <Routes>
                                <Route
                                    element={
                                        <Layout
                                            hidePaths={[
                                                ...PublicRoutes(
                                                    FeatureFlag
                                                ).flatMap((route) => [
                                                    `/${route.path}/`,
                                                    `/${route.path}`,
                                                ]),
                                                "/hp/signup-wizard/",
                                                "/hp/signup-wizard",
                                            ]}
                                            locale={locale}
                                        />
                                    }
                                >
                                    {GlobalRoutes.map((route) => {
                                        return (
                                            <Route
                                                key={route.path}
                                                path={route.path}
                                                element={
                                                    <RequireAuth>
                                                        <Suspense
                                                            fallback={
                                                                <ScalersLoader />
                                                            }
                                                        >
                                                            <route.element />
                                                        </Suspense>
                                                    </RequireAuth>
                                                }
                                            />
                                        );
                                    })}
                                    {SalesHPRoutes.map((route) => {
                                        const WrappedComponent = withBodyClass(
                                            route.element,
                                            "client-facing"
                                        );
                                        return (
                                            <Route
                                                key={route.path}
                                                path={route.path}
                                                element={
                                                    <RequireAuth>
                                                        <RequireSubscription
                                                            locale={locale}
                                                        >
                                                            <RequireVerticalSubscription
                                                                isVerticalRequired={
                                                                    route.isVerticalRequired
                                                                }
                                                                expectedVertical={
                                                                    Verticals.Sales
                                                                }
                                                            >
                                                                <Suspense
                                                                    fallback={
                                                                        <ContentLoader />
                                                                    }
                                                                >
                                                                    <WrappedComponent
                                                                        t={t}
                                                                        locale={
                                                                            locale
                                                                        }
                                                                        {...route.props}
                                                                    />
                                                                </Suspense>
                                                            </RequireVerticalSubscription>
                                                        </RequireSubscription>
                                                    </RequireAuth>
                                                }
                                            />
                                        );
                                    })}
                                    {MarketingHPRoutes.map((route) => {
                                        const WrappedComponent = withBodyClass(
                                            route.element,
                                            "marketing"
                                        );
                                        return (
                                            <Route
                                                key={route.path}
                                                path={route.path}
                                                element={
                                                    <RequireAuth>
                                                        <RequireSubscription
                                                            locale={locale}
                                                        >
                                                            <RequireVerticalSubscription
                                                                isVerticalRequired={
                                                                    route.isVerticalRequired
                                                                }
                                                                expectedVertical={
                                                                    Verticals.Marketing
                                                                }
                                                            >
                                                                <Suspense
                                                                    fallback={
                                                                        <ContentLoader />
                                                                    }
                                                                >
                                                                    <WrappedComponent
                                                                        t={t}
                                                                        locale={
                                                                            locale
                                                                        }
                                                                        {...route.props}
                                                                    />
                                                                </Suspense>
                                                            </RequireVerticalSubscription>
                                                        </RequireSubscription>
                                                    </RequireAuth>
                                                }
                                            />
                                        );
                                    })}
                                    {AccountingRoutes.map((route) => {
                                        const WrappedComponent = withBodyClass(
                                            route.element,
                                            "accounting"
                                        );
                                        return (
                                            <Route
                                                key={route.path}
                                                path={route.path}
                                                element={
                                                    <RequireAuth>
                                                        <RequireVerticalSubscription
                                                            isVerticalRequired={
                                                                false
                                                            }
                                                            expectedVertical={
                                                                Verticals.Accounting
                                                            }
                                                        >
                                                            <Suspense
                                                                fallback={
                                                                    <ContentLoader />
                                                                }
                                                            >
                                                                <WrappedComponent
                                                                    t={t}
                                                                    locale={
                                                                        locale
                                                                    }
                                                                    {...route.props}
                                                                />
                                                            </Suspense>
                                                        </RequireVerticalSubscription>
                                                    </RequireAuth>
                                                }
                                            />
                                        );
                                    })}
                                    {SalesCPRoutes.map((route) => (
                                        <Route
                                            key={route.path}
                                            path={route.path}
                                            element={
                                                <RequireAuth>
                                                    <RequireSubscription
                                                        locale={locale}
                                                    >
                                                        <Suspense
                                                            fallback={
                                                                <ContentLoader />
                                                            }
                                                        >
                                                            <route.element
                                                                t={t}
                                                                locale={locale}
                                                                {...route.props}
                                                            />
                                                        </Suspense>
                                                    </RequireSubscription>
                                                </RequireAuth>
                                            }
                                        />
                                    ))}
                                    {Object.keys(FeatureFlag).length > 0 &&
                                        PublicRoutes(FeatureFlag).map(
                                            (route) => (
                                                <Route
                                                    key={`${route.path}`}
                                                    path={`${route.path}`}
                                                    element={
                                                        <Suspense
                                                            fallback={
                                                                <ScalersLoader />
                                                            }
                                                        >
                                                            <route.element
                                                                t={t}
                                                                locale={locale}
                                                            />
                                                        </Suspense>
                                                    }
                                                />
                                            )
                                        )}
                                </Route>
                            </Routes>
                        </RequireAuthRedirect>
                    </ErrorBoundary>
                </Router>
            </div>
        </AppProviders>
    );
};

export default _App;
