import { RouteInterface } from "@/core/interfaces/route.interface";
import { lazy } from "react";

const SignupWizard = lazy(() => import("@/pages/hp/SignupWizard"));

const routes: Array<RouteInterface> = [
    {
        path: "hp/signup-wizard",
        element: SignupWizard,
        name: "Signup Wizard",
    },
];

const GlobalRoutes = routes;
export default GlobalRoutes;
