import React, { MouseEvent, useState } from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import VerticalDropdownButton from "@/components/atoms/dropdown-button/vertical-dropdown-button";
import Verticals from "@/core/enums/verticals.enum";
import useVerticalButton from "./hooks/useVerticalButton";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

interface VerticalButtonProps {
    isSideBarOpen: boolean;
    selectedVertical: Verticals;
    updateSelectedVertical: (vertical: Verticals) => void;
    pathname: string;
}

const VerticalButton: React.FC<VerticalButtonProps> = ({
    isSideBarOpen,
    selectedVertical,
    updateSelectedVertical,
    pathname,
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { icon, buttonText, variant } = useVerticalButton(selectedVertical);
    const openDropdown = (event: MouseEvent<HTMLElement>) => {
        if (anchorEl) {
            setAnchorEl(null);
        } else {
            setAnchorEl(event.currentTarget);
        }
    };
    const enableAccountingv1 = useFeatureIsOn("enable_accounting_v1");

    return (
        <div className="vertical-button">
            <VerticalDropdownButton
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                buttonText={buttonText}
                onClick={openDropdown}
                icon={icon}
                isExpended={isSideBarOpen}
                isMobile={false}
                variant={
                    variant as "primary" | "secondary" | "success" | "accent"
                }
            >
                <div
                    className={clsx("vertical-content", {
                        "vertical-content--primary":
                            selectedVertical === Verticals.Sales,
                        "vertical-content--secondary":
                            selectedVertical === Verticals.Marketing,
                        "vertical-content--accent":
                            selectedVertical === Verticals.Accounting,
                    })}
                >
                    <Button
                        className={clsx(
                            "vertical-buttons",
                            "vertical-buttons--primary",
                            {
                                "vertical-buttons--primary--selected":
                                    selectedVertical === Verticals.Sales,
                            }
                        )}
                        onClick={() => {
                            updateSelectedVertical(Verticals.Sales);
                            if (pathname !== "/hp/candidates")
                                navigate("hp/candidates");
                            setAnchorEl(null);
                        }}
                    >
                        {t("vertical.clientFacing")}
                        <p className="vertical-buttons__content">
                            {t("vertical.client-facing.label")}
                        </p>
                    </Button>
                    <Button
                        className={clsx(
                            "vertical-buttons",
                            "vertical-buttons--secondary",
                            {
                                "vertical-buttons--secondary--selected":
                                    selectedVertical === Verticals.Marketing,
                            }
                        )}
                        onClick={() => {
                            setAnchorEl(null);
                            updateSelectedVertical(Verticals.Marketing);
                            if (pathname !== "/hp/marketing/candidates")
                                navigate("hp/marketing/candidates");
                        }}
                    >
                        {t("vertical.marketing")}
                        <p className="vertical-buttons__content">
                            {t("vertical.marketing-content.label")}
                        </p>
                    </Button>
                    {enableAccountingv1 && (
                        <Button
                            className={clsx(
                                "vertical-buttons",
                                "vertical-buttons--accent",
                                {
                                    "vertical-buttons--accent--selected":
                                        selectedVertical ===
                                        Verticals.Accounting,
                                }
                            )}
                            onClick={() => {
                                setAnchorEl(null);
                                updateSelectedVertical(Verticals.Accounting);
                                if (pathname !== "/hp/accounting/candidates")
                                    navigate("hp/accounting/candidates");
                            }}
                        >
                            {t("vertical.accounting")}
                            <p className="vertical-buttons__content">
                                {t("vertical.accounting-content.label")}
                            </p>
                        </Button>
                    )}
                </div>
            </VerticalDropdownButton>
        </div>
    );
};

export default VerticalButton;
