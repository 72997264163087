import { getUser } from "@/contexts/UserContext";
import Verticals from "@/core/enums/verticals.enum";
import { setSelectedVertical } from "@/core/stores/verticals/selected-vertical.store";
import BookSubscriptionCall from "@/pages/hp/BookSubscriptionCall";
import singletonLocalStorage from "@/utils/localStoring";
import React, { useEffect } from "react";
import { RootStateOrAny } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

type Props = {
    isVerticalRequired?: boolean;
    expectedVertical: Verticals;
    children: React.ReactNode;
};

const cleanPathname = (pathname: string) =>
    pathname.replace(/\/+/g, "/").replace(/^\/hp\/hp/, "/hp");

const RequireVerticalSubscription: React.FC<Props> = (props: Props) => {
    const { children, expectedVertical, isVerticalRequired } = props;
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const isMarketing = /\/marketing\//.test(location.pathname);
    const isSales = !isMarketing;
    const isAccounting = /\/accounting\//.test(location.pathname);

    const currUser = getUser();
    const verticals = currUser?.company?.active_subscription?.verticals.map(
        (vertical) => vertical.id
    );

    const lastSelectedVertical = singletonLocalStorage.getItem("vertical");
    const selectedVertical = useSelector(
        (state: RootStateOrAny) => state.selectedVertical.vertical
    );

    useEffect(() => {
        // Handle the first reach to the app
        if (!selectedVertical && lastSelectedVertical) {
            dispatch(setSelectedVertical(lastSelectedVertical));

            if (lastSelectedVertical === Verticals.Sales && isMarketing) {
                const expectedPageToVisit = cleanPathname(
                    `/hp${location.pathname.replace("/hp/marketing", "")}`
                );
                navigate(expectedPageToVisit, { replace: true });
            } else if (
                lastSelectedVertical === Verticals.Marketing &&
                isSales
            ) {
                const expectedPageToVisit = cleanPathname(
                    `/hp/marketing${location.pathname.replace("/hp", "")}`
                );
                navigate(expectedPageToVisit, { replace: true });
            }
        }
    }, [
        dispatch,
        selectedVertical,
        lastSelectedVertical,
        isMarketing,
        isSales,
        location.pathname,
        navigate,
    ]);

    if (currUser?.company?.free_trial) {
        if (!selectedVertical) {
            dispatch(setSelectedVertical(Verticals.Sales));
        }
        return children;
    }

    if (isAccounting && !selectedVertical && lastSelectedVertical) {
        if (lastSelectedVertical === Verticals.Sales) {
            dispatch(setSelectedVertical(Verticals.Accounting));
        } else if (lastSelectedVertical === Verticals.Marketing) {
            dispatch(setSelectedVertical(Verticals.Accounting));
        }
    }
    if (!selectedVertical && verticals && verticals.length > 0) {
        const isSalesVerticalPresent = verticals.some((vertical) => {
            if (vertical === Verticals.Sales) {
                return true;
            }
            return false;
        });
        if (!isSalesVerticalPresent) {
            dispatch(setSelectedVertical(Verticals.Marketing));
            const expectedPageToVisit = cleanPathname(
                `/hp/marketing${location.pathname.replace("/hp", "").replace("/marketing", "")}`
            );
            return <Navigate to={expectedPageToVisit} replace />;
        } else {
            dispatch(setSelectedVertical(Verticals.Sales));
            const expectedPageToVisit = cleanPathname(
                `/hp${location.pathname.replace("/hp/marketing", "")}`
            );
            return <Navigate to={expectedPageToVisit} replace />;
        }
    }
    // Handle cases when the user is trying to access the wrong path based on their last selected vertical
    if (selectedVertical === Verticals.Sales && isMarketing) {
        const expectedPageToVisit = cleanPathname(
            `/hp${location.pathname.replace("/hp/marketing", "")}`
        );
        return <Navigate to={expectedPageToVisit} replace />;
    } else if (selectedVertical === Verticals.Marketing && isSales) {
        const expectedPageToVisit = cleanPathname(
            `/hp/marketing${location.pathname.replace("accounting/", "").replace("/hp", "")}`
        );
        return <Navigate to={expectedPageToVisit} replace />;
    }

    // Aahad's work. The Book subscription call keeps on flickering
    if (!currUser?.id) {
        return <></>;
    }

    // Return the BookSubscriptionCall if the verticals array is not available or empty
    if (!verticals || verticals.length === 0) {
        return <BookSubscriptionCall />;
    }

    // If the vertical is required but not included in the active subscription, show BookSubscriptionCall
    if (isVerticalRequired && !verticals.includes(expectedVertical)) {
        return <BookSubscriptionCall />;
    }

    // Return the children directly if the vertical is not required
    if (!isVerticalRequired) {
        return children;
    }

    // Check if the last selected vertical is in the active subscription
    if (lastSelectedVertical && verticals.includes(lastSelectedVertical)) {
        return children;
    }

    return children;
};

export default RequireVerticalSubscription;
