import React, { FC, ReactElement } from "react";
import "@/../static/css/modal.scss";
import Modal from "./Modal";
import { InfoOutlined } from "@mui/icons-material";

type Props = {
    type: string;
    title: string;
    buttonChild: ReactElement;
};

const InfoModal: FC<Props> = ({ type, title, buttonChild }: Props) => {
    return (
        <Modal type={type} closable={false}>
            <InfoOutlined className="modal-icon" />
            <Modal.Title content={title} />
            {buttonChild}
        </Modal>
    );
};

export default InfoModal;
