import { getUser } from "@/contexts/UserContext";
import { Mixpanel } from "../Mixpanel";
import { captureEvent, trackTime } from "../Mixpanel";
import {
    prefix_to_object,
    parse_query_params,
    free_trial_props,
    getSubscriptionDetails,
    getActiveVertical,
} from "../utils";
import store from "@/core/stores";

export class CuratedListTracking {
    state = store.getState();
    constructor(props) {
        this.currUser = getUser();
        this.vertical = this.state.selectedVertical.vertical;
        this.props = props;
        this.page_enter = "Curated List Viewed";
        this.page_enter_exit = this.page_enter + " - Page Exited";
        (this.source = parse_query_params().source),
            (this.currUser = getUser());
    }

    page_view = (length, props) => {
        captureEvent(this.page_enter, {
            number_of_curations_currently: length,
            ...props,
        });
        trackTime(this.page_enter_exit);
    };

    curated_candidate_view = (candidate, position, job) => {
        captureEvent("Curated Candidate Viewed", {
            From: this.source,
            position: position,
            job,
            number_of_curations_currently:
                this.props.currCandidates.current.length,
            ...prefix_to_object(candidate, "candidate_"),
            ...getSubscriptionDetails(this.currUser),
            ...getActiveVertical(this.vertical),
        });
        trackTime("Curated Candidate Viewed - Exit");
    };

    curated_candidate_exit = (candidates, currentIndex, action, jobLink) => {
        console.log("jobLink", jobLink);
        captureEvent("Curated Candidate Viewed - Exit", {
            action: action,
            position: currentIndex + 1,
            number_of_curations_currently: candidates.length,
            job_link: jobLink,
            ...prefix_to_object(
                candidates[currentIndex].candidate,
                "candidate_"
            ),
            ...getSubscriptionDetails(this.currUser),
            ...getActiveVertical(this.vertical),
        });
    };

    page_exit = (currentCandidates, currentIndex) => {
        let candidates = currentCandidates.current;
        let index = currentIndex.current;
        let candidate_details_with_prefix = undefined;
        if (candidates.length > 0) {
            let length = candidates.length;
            let candidate_details = candidates[index].candidate;
            candidate_details_with_prefix = prefix_to_object(
                candidate_details,
                "candidate_when_exited_"
            );
            if (length > 0) {
                let jobLink = "";
                const baseUrl = import.meta.env.VITE_BASE_URL;
                if (this.props.selectedJobRef) {
                    jobLink = `${baseUrl}/hp/jobs/${this.props.selectedJobRef.current.uuid}`;
                }
                this.curated_candidate_exit(
                    candidates,
                    index,
                    "Page Exit",
                    jobLink
                );
            }
        }
        captureEvent(this.page_enter_exit, {
            number_of_curations_currently: length,
            ...candidate_details_with_prefix,
            ...getSubscriptionDetails(this.currUser),
            ...getActiveVertical(this.vertical),
        });
    };

    shortlist_candidate = () => {
        let candidates = this.props.currCandidates.current;
        let currentIndex = this.props.currIndRef.current;
        captureEvent("Candidate Shortlisted", {
            number_of_curations_currently: candidates.length,
            from: "Curated List",
            position: currentIndex + 1,
            ...free_trial_props(this.currUser),
            ...prefix_to_object(
                candidates[currentIndex].candidate,
                "candidate_"
            ),
            ...getSubscriptionDetails(this.currUser),
            ...getActiveVertical(this.vertical),
        });
        Mixpanel.people.increment("total_shortlist_count", 1);
        this.curated_candidate_exit(
            candidates,
            currentIndex,
            "Candidate Shortlisted"
        );
    };

    remove_candidate = (reasons) => {
        let candidates = this.props.currCandidates.current;
        let currentIndex = this.props.currIndRef.current;
        captureEvent("Curated Candidate Removed", {
            reasons: reasons,
            number_of_curations_currently: candidates.length,
            position: currentIndex + 1,
            ...prefix_to_object(
                candidates[currentIndex].candidate,
                "candidate_"
            ),
            ...getSubscriptionDetails(this.currUser),
            ...getActiveVertical(this.vertical),
        });
        this.curated_candidate_exit(
            candidates,
            currentIndex,
            "Candidate Removed"
        );
    };

    all_actions = (action, length) => {
        captureEvent(action, {
            curated_list_total: length,
            from: "Curated List Page",
            ...getSubscriptionDetails(this.currUser),
            ...getActiveVertical(this.vertical),
        });
    };

    video_actions = (actionName, extraProps) => {
        let candidate =
            this.props.currCandidates.current[this.props.currIndRef.current]
                .candidate;
        captureEvent(actionName, {
            from: "Curated List",
            ...candidate,
            ...extraProps,
            ...getSubscriptionDetails(this.currUser),
            ...getActiveVertical(this.vertical),
        });
    };

    curated_job_selected = (company, job, curated_candidates) => {
        captureEvent("Curated Job selected", {
            from: "Curated List",
            ...prefix_to_object(company, "company_"),
            job,
            curated_candidates,
            ...getSubscriptionDetails(this.currUser),
            ...getActiveVertical(this.vertical),
        });
    };
    curated_job_selected_exited = (company, job, duration) => {
        captureEvent("Curated Job selected - Exited", {
            from: "Curated List",
            ...prefix_to_object(company, "company_"),
            job,
            duration,
            ...getSubscriptionDetails(this.currUser),
            ...getActiveVertical(this.vertical),
        });
    };
}
