import React, { useContext } from "react";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Controller } from "react-hook-form";
import "@/../static/css/myradiogroup.css"
import LocaleContext from "../../contexts/LocaleContext";

export default function MyRadioGroup(props) {
    const { options, control, valueName, disabled, onChange = (functiontodo) => functiontodo} = props
    const { locale } = useContext(LocaleContext)

    return(
        <Controller
            control={control}
            name={valueName}
            defaultValue={options[0]}
            render={({field}) => (
                <RadioGroup
                    {...field}
                    value={field.value.id}
                    onChange={(e) => {
                        var value = parseInt(e.target.value)
                        var findoption = options.find((o) => o.id == value)
                        onChange(field.onChange(findoption))
                    }}
                    row
                    className={disabled ? "disabled" : ""}
                    name="row-radio-buttons-group"
                >
                    {
                        options?.map((option, index) => (
                            <FormControlLabel key={index} value={option.id} 
                            control={<Radio />} label={option[`name_${locale}`]} disabled={disabled} />
                        )) 
                    }
                </RadioGroup>
            )}
        />
    )
}