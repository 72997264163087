import { RouteInterface } from "@/core/interfaces/route.interface";
import { lazy } from "react";
import { fake_candidate_new } from "@/pages/hp/components/fakecandidatedata";
import {
    ListOutlined,
    MessageOutlined,
    WorkOutlineOutlined,
} from "@material-ui/icons";
import { PeopleOutlineOutlined, RouteOutlined } from "@mui/icons-material";

const Candidates = lazy(() => import("@/pages/hp/Candidates"));
const CandidateNew = lazy(() => import("@/pages/hp/CandidateNew"));
const Messages = lazy(() => import("@/pages/hp/Messages"));
const JobsNew = lazy(() => import("@/pages/hp/JobsNew"));
const ViewJobNew = lazy(() => import("@/pages/hp/ViewJobNew"));
const CreateJobNew = lazy(() => import("@/pages/hp/CreateJobNew"));
const EditJob = lazy(() => import("@/pages/hp/EditJob"));
const SuccessJobPosted = lazy(
    () => import("@/pages/hp/components/JobFormSteps/Success")
);
const CuratedList = lazy(() => import("@/pages/hp/CuratedList"));
const CuratedListNew = lazy(() => import("@/pages/hp/CuratedListNew"));
const Settings = lazy(() => import("@/pages/hp/Settings"));
const NewPipeline = lazy(() => import("@/pages/hp/NewPipeline"));

const routes: Array<RouteInterface> = [
    {
        path: "hp/candidates",
        element: Candidates,
        isMenuItem: true,
        name: "candidate-list",
        icon: PeopleOutlineOutlined,
        props: {
            open_jobs_endpoint: "job/open-jobs-list/?vertical_id=1",
        },
        isVerticalRequired: true,
    },
    {
        path: "hp/jobs",
        element: JobsNew,
        isMenuItem: true,
        name: "jobs",
        icon: WorkOutlineOutlined,
        isVerticalRequired: true,
        props: {
            endpoint: "job/list?vertical_id=1&active=",
        },
    },
    {
        path: "hp/pipeline",
        element: NewPipeline,
        isMenuItem: true,
        name: "pipeline",
        icon: RouteOutlined,
        isVerticalRequired: true,
        props: {
            open_jobs_endpoint: "job/open-jobs-list/?vertical_id=1",
            vertical: "sales",
        },
    },
    {
        path: "hp/curated_list",
        element: CuratedListNew,
        isMenuItem: true,
        name: "curated_list",
        icon: ListOutlined,
        isVerticalRequired: true,
        props: {
            vertical: "sales/",
            open_jobs_endpoint: "job/open-jobs-list/?vertical_id=1",
            jobs_endpoint: "job/list?vertical_id=1&active=true",
        },
    },
    {
        path: "hp/messages",
        element: Messages,
        isMenuItem: true,
        name: "messages",
        props: {
            applicantUrl: "/applicant/sales/get/",
        },
        icon: MessageOutlined,
    },
    {
        path: "hp/candidates/:candidateId",
        element: CandidateNew,
        isVerticalRequired: true,
        props: {
            applicantUrl: "/applicant/sales/get/",
            open_jobs_endpoint: "job/open-jobs-list/?vertical_id=1",
        },
    },
    {
        path: "hp/candidates/demo",
        element: CandidateNew, // Assuming demo uses the same component
        props: {
            generic: true,
            fakeCandidate: fake_candidate_new,
        },
    },
    {
        path: "hp/candidates/generic/:candidateId",
        element: CandidateNew, // Assuming generic uses the same component
        props: {
            generic: true,
        },
        isVerticalRequired: true,
    },
    {
        path: "hp/settings",
        element: Settings,
    },
    {
        path: "hp/jobs/:job_id",
        element: ViewJobNew,
        isVerticalRequired: true,
        props: {
            applicantUrl: "/applicant/sales/get/",
        },
    },
    {
        path: "hp/jobs/create_job",
        element: CreateJobNew,
        isVerticalRequired: true,
    },
    {
        path: "hp/jobs/duplicate_job/:job_id",
        element: CreateJobNew,
        isVerticalRequired: true,
    },
    {
        path: "hp/jobs/create_job_new",
        element: CreateJobNew,
        isVerticalRequired: true,
    },
    {
        path: "hp/jobs/create_job/success",
        element: SuccessJobPosted,
        isVerticalRequired: true,
    },
    {
        path: "hp/jobs/edit_job/:job_id",
        element: EditJob,
        isVerticalRequired: true,
    },
    {
        path: "hp/onboarding",
        element: CuratedList,
    },
    {
        path: "hp/*",
        element: Candidates, // Default route for unspecified HP paths
        isVerticalRequired: true,
        props: {
            open_jobs_endpoint: "job/open-jobs-list/?vertical_id=1",
        },
    },
];

const SalesHPRoutes = routes;
export default SalesHPRoutes;
