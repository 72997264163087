import React from "react";
import { Stack } from "@mui/material";
import Lottie from "lottie-react";
import animationPrimary from "@/../static/animations/OTTeAB2K7N.json";

const ScalersLoader: React.FC = () => {
    return (
        <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            height="100%"
        >
            <Lottie
                animationData={animationPrimary}
                style={{
                    width: "180px",
                    height: "180px",
                }}
            />
        </Stack>
    );
};

export default ScalersLoader;
