import React from "react";
import {
    MessageOutlined,
    SettingsOutlined,
    WorkOutlineOutlined,
    ListOutlined,
    PeopleOutline,
} from "@material-ui/icons";
import { BoltOutlined, PeopleOutlineOutlined, RouteOutlined } from "@mui/icons-material";

export const getCandidatePages = (t) => {
    return [
        {
            to: "/candidate/jobs",
            text: t("available_jobs"),
            icon: <WorkOutlineOutlined fontSize="small" />,
            authRequired: false,
        },
        {
            to: "/candidate/profile",
            text: t("my_profile"),
            icon: <SettingsOutlined fontSize="small" />,
            authRequired: true,
        },
        {
            to: "/candidate/messages",
            text: t("messages"),
            icon: <MessageOutlined fontSize="small" />,
            authRequired: true,
            isMessageLink: true,
        },
    ];
};

export const getFTCandidatePages = (t) => {
    return [
        {
            to: "/candidate/ft-vetting-process",
            text: t("ft_vetting_process"),
            icon: <BoltOutlined></BoltOutlined>,
            authRequired: true,
        },
        {
            to: "/candidate/jobs",
            text: t("available_jobs"),
            icon: <WorkOutlineOutlined />,
            authRequired: false,
        },
        {
            to: "/candidate/profile",
            text: t("my_profile"),
            icon: <SettingsOutlined />,
            authRequired: true,
        },
        {
            to: "/candidate/messages",
            text: t("messages"),
            icon: <MessageOutlined />,
            authRequired: true,
            isMessageLink: true,
        },
    ];
};

export const getHiringPartnerPages = (t) => {
    return [
        {
            to: "/hp/candidates",
            text: t("candidate-list"),
            icon: <PeopleOutlineOutlined fontSize="small" />,
            authRequired: true,
        },
        {
            to: "/hp/pipeline",
            text: t("pipeline"),
            icon: <RouteOutlined fontSize="small" />,
            authRequired: true,
        },
        {
            to: "/hp/curated_list",
            text: t("curated_list"),
            icon: <ListOutlined fontSize="small" />,
            authRequired: true,
            isCuratedListLink: true,
        },
        {
            to: "/hp/messages",
            text: t("messages"),
            icon: <MessageOutlined fontSize="small" />,
            authRequired: true,
            isMessageLink: true,
        },
        {
            to: "/hp/jobs",
            text: t("jobs"),
            icon: <WorkOutlineOutlined fontSize="small" />,
            authRequired: true,
            isJobsLink: true,
        },
    ];
};

export const getMarketingPages = (t) => {
    return [
        {
            to: "#",
            text: t("candidate-list"),
            icon: <PeopleOutlineOutlined fontSize="small" />,
            authRequired: true,
            nonActiveLink: true,
        },
        {
            to: "#",
            text: t("pipeline"),
            icon: <RouteOutlined fontSize="small" />,
            authRequired: true,
            nonActiveLink: true,
        },
        {
            to: "#",
            text: t("curated_list"),
            icon: <ListOutlined fontSize="small" />,
            authRequired: true,
            isCuratedListLink: true,
            nonActiveLink: true,
        },
        {
            to: "#",
            text: t("jobs"),
            icon: <WorkOutlineOutlined fontSize="small" />,
            authRequired: true,
            isJobsLink: true,
            nonActiveLink: true,
        },
    ];
}

// setting image colors
const imageAltColors = [
    "var(--scalers-success-300)",
    "var(--scalers-danger-300)",
    "var(--scalers-warning-300)",
    "var(--scalers-purple-50)",
];

// get random color
export const getRandomColor = () => {
    return imageAltColors[Math.floor(Math.random() * imageAltColors.length)];
};
