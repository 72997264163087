import { TFunction } from "i18next";
import * as yup from "yup";
import { NavigateFunction } from "react-router-dom";
import { CurrUserDetails } from "@/utils/currUserType";
import Verticals from "@/core/enums/verticals.enum";
import { needsToFillPlacement } from "@/utils/utilFunctions";

export const UserTypes = {
    HP: 1,
    CP: 2,
};

export const LoginMethod = {
    OTP: 1,
    EmailAndPass: 2,
};

export const emailOrPhoneValidationSchema = (
    t: TFunction<"translation", undefined, "translation">
) => {
    return yup.object().shape({
        email_or_phone: yup
            .string()
            .required(t("validation.required"))
            .test("email_or_phone", t("login.hp.email_err"), function (value) {
                if (
                    /^\+?\d+$/.test(value) &&
                    !validatePhone(t).isValidSync(value)
                ) {
                    return this.createError({
                        message: t("login.hp.phone_err"),
                    });
                } else if (
                    !/^\+?\d+$/.test(value) &&
                    !validateEmail(t).isValidSync(value)
                ) {
                    return this.createError({
                        message: t("login.hp.email_err"),
                    });
                }
                return (
                    validateEmail(t).isValidSync(value) ||
                    validatePhone(t).isValidSync(value)
                );
            }),
    });
};

const validateEmail = (
    t: TFunction<"translation", undefined, "translation">
) => {
    return yup.string().email(t("login.hp.email_err"));
};

const containsOnlyNumbersAndStarts05 = /^05\d+$/;

const validatePhone = (
    t: TFunction<"translation", undefined, "translation">
) => {
    return yup
        .string()
        .matches(containsOnlyNumbersAndStarts05, t("login.hp.phone_err"))
        .min(10)
        .max(10);
};

export const isValidPhone = (input: string) => {
    return containsOnlyNumbersAndStarts05.test(input);
};

export const handleHPNavigate = (
    navigate: NavigateFunction,
    hp: CurrUserDetails,
    from?: string
) => {
    if (hp.signup_status === true) {
        navigate("hp/signup-wizard");
    } else if (from) {
        navigate(from);
    } else {
        const company_verticals = hp.company!.active_subscription.verticals;
        const isSalesVerticalPresent = company_verticals.some((vertical) => {
            if (vertical.id === Verticals.Sales) {
                navigate("hp/candidates", { replace: true });
                return true;
            }
            return false;
        });

        if (!isSalesVerticalPresent) {
            navigate("hp/marketing/candidates", { replace: true });
        }
    }
};

export const handleGeneralNavigation = (
    userData: CurrUserDetails,
    navigate: NavigateFunction,
    from?: string
) => {
    if (userData.views === "hp") {
        handleHPNavigate(navigate, userData, from);
    } else {
        if (from) {
            navigate(from);
        } else {
            let link: string = userData?.views ?? "";
            switch (userData.views) {
                case "candidate":
                    link += "/jobs";
                    break;
                case "fast-tracker":
                    link = "candidate/ft-vetting-process";
            }
            navigate(link, { replace: true });
            if (needsToFillPlacement(userData)) {
                document.getElementById("complete-ur-profile")!.style.display =
                    "flex";
            }
        }
    }
};
