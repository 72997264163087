import store from "@/core/stores";
import { Mixpanel, captureEvent } from "../Mixpanel";
import {
    getActiveVertical,
    getSubscriptionDetails,
    prefix_to_object,
} from "../utils";
import { getUser } from "@/contexts/UserContext";

export class PipelineTrack {
    state = store.getState();
    constructor() {
        this.currUser = getUser();
        this.vertical = this.state.selectedVertical.vertical;
    }
    delist_candidate = (action, reasons, candidate_details) => {
        captureEvent(action, {
            no_hire_reasons: reasons,
            from: "Pipeline Page",
            ...candidate_details,
            ...getSubscriptionDetails(this.currUser),
            ...getActiveVertical(this.vertical),
        });
        Mixpanel.people.increment("total_delist", 1);
    };
    move_candidate = (
        actionFrom,
        from,
        to,
        candidate_details,
        job_details = {}
    ) => {
        captureEvent("Pipeline Transition", {
            action_from: actionFrom,
            from: from,
            to: to,
            ...candidate_details,
            ...prefix_to_object(job_details, "job_"),
            ...getSubscriptionDetails(this.currUser),
            ...getActiveVertical(this.vertical),
        });
    };
    pipeline_job_change = (
        eventName,
        job_details = {},
        from,
        to,
        isNewest,
        no_of_applicants,
        hp,
        duration
    ) => {
        let durationData = {};
        if (duration) {
            durationData.duration = duration;
        }
        captureEvent(eventName, {
            from: from,
            to: to,
            isNewest,
            no_of_applicants,
            ...prefix_to_object(job_details, "job_"),
            ...prefix_to_object(hp, "hp_"),
            ...durationData,
            ...getSubscriptionDetails(this.currUser),
            ...getActiveVertical(this.vertical),
        });
    };
}
