import React, { useEffect, useRef, useState } from "react";
import { Button, Popover, Backdrop, Portal } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import "./vertical-dropdown-button.scss";
import i18n from "@/i18n";
import styled from "@emotion/styled";
import clsx from "clsx";
import { LightTooltip } from "@/components/Header/components/menu-item/menu-item";

interface DropdownButtonProps {
    anchorEl: null | HTMLElement;
    setAnchorEl: (el: null | HTMLElement) => void;
    variant?: "primary" | "secondary" | "success" | "accent";
    isExpended?: boolean;
    buttonText: string;
    onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
    icon?: React.ReactNode;
    children: React.ReactNode;
    isMobile?: boolean;
}

const VerticalDropdownButton: React.FC<DropdownButtonProps> = ({
    anchorEl,
    setAnchorEl,
    variant = "primary",
    isExpended = false,
    isMobile = true,
    buttonText,
    onClick,
    icon,
    children,
}) => {
    const dir = i18n.dir();
    const buttonRef = useRef<HTMLButtonElement>(null);
    const [buttonWidth, setButtonWidth] = useState(0);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        onClick(event);
    };
    const handleClose = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        setAnchorEl(null);
    };

    useEffect(() => {
        const updateWidth = () => {
            setTimeout(() => {
                if (buttonRef.current) {
                    const { width } = buttonRef.current.getBoundingClientRect();
                    setButtonWidth(width);
                }
            }, 1500); // A short delay
        };

        updateWidth();
        window.addEventListener("resize", updateWidth);

        return () => window.removeEventListener("resize", updateWidth);
    }, []);

    const StyledPopover = styled(Popover)({
        ".MuiPopover-paper": {
            maxHeight: "340px",
            width: "auto",
            borderRadius: "8px",
            boxShadow:
                "0px 0px 1px 0px rgba(42, 39, 49, 0.05), 0px 2px 4px 0px rgba(42, 39, 49, 0.10)",
        },
    });

    const endIconHandler = () => {
        return open ? <KeyboardArrowUp /> : <KeyboardArrowDown />;
    };

    return (
        <div
            className={clsx("vertical-dropdown-button", {
                "vertical-dropdown-button__expanded": isExpended,
            })}
        >
            <LightTooltip title={buttonText} show={!isExpended}>
                <Button
                    ref={buttonRef}
                    className={clsx("vertical-button", {
                        "vertical-button--primary": variant === "primary",
                        "vertical-button--secondary": variant === "secondary",
                        "vertical-button--accent": variant === "accent",
                        "vertical-button--expanded": isExpended,
                    })}
                    aria-controls={open ? "dropdown-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                    startIcon={icon}
                    endIcon={isExpended && endIconHandler()}
                    data-testid={`${VerticalDropdownButton.name}-${variant}`}
                >
                    {isExpended && buttonText}
                </Button>
            </LightTooltip>

            {isMobile && open && children}
            {buttonWidth !== null && !isMobile && (
                <Portal>
                    <Backdrop
                        invisible={window.innerWidth <= 768 ? false : true}
                        open={open}
                    >
                        <StyledPopover
                            id="dropdown-menu-popover"
                            className="vertical-dropdown-button__popover"
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: dir === "rtl" ? "left" : "right",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: dir === "rtl" ? "right" : "left",
                            }}
                            onClick={(e) => e.stopPropagation()}
                        >
                            {children}
                        </StyledPopover>
                    </Backdrop>
                </Portal>
            )}
        </div>
    );
};

export default VerticalDropdownButton;
