import React, { useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { RootStateOrAny, useDispatch } from "react-redux";
import { setSelectedVertical } from "@/core/stores/verticals/selected-vertical.store";
import SalesHPRoutes from "@/core/routes/hiring-partner/sales.routes";
import MarketingHPRoutes from "@/core/routes/hiring-partner/marketing.routes";
import SalesCPRoutes from "@/core/routes/candidate/sales.routes";
import useMediaBreakpoint from "../../hooks/useMediaBreakpoint";
import LocaleContext from "@/contexts/LocaleContext";
import { getUser } from "@/contexts/UserContext";
import { FeatureFlag } from "@/feature-flag";
import SideBarHeader from "./sidebar/SideBarHeader";
import VerticalButton from "./sidebar/VerticalButton";
import MenuItems from "./sidebar/MenuItems";
import SideBarFooter from "./sidebar/SideBarFooter";
import { Drawer } from "./sidebar/styles";
import { onBoardingStyle } from "./sidebar/utils";
import NotificationMenu from "../Header/components/menu-item/notification-menu";
import "./sidebar.scss";
import Verticals from "@/core/enums/verticals.enum";
import { useSelector } from "react-redux";
import AccountingRoutes from "@/core/routes/hiring-partner/accounting.routes";

interface SideBarProps {
    isSideBarOpen: boolean;
    setSideBarVisibility: (visibility: boolean) => void;
    messagesNotify: boolean;
    curatedListCount: number;
    jobApplicants: number;
    changeLang: (lang: string) => void;
}

const SideBar: React.FC<SideBarProps> = ({
    isSideBarOpen,
    setSideBarVisibility,
    messagesNotify,
    curatedListCount,
    jobApplicants,
    changeLang,
}) => {
    const useMediaQuery = useMediaBreakpoint();
    const isMobileView = ["xs", "sm"].includes(useMediaQuery.breakpoint);
    const { locale } = useContext(LocaleContext);
    const currentUser = getUser();
    const dispatch = useDispatch();

    useEffect(() => {
        if (isMobileView) {
            setSideBarVisibility(false);
        }
    }, [isMobileView]);

    const { pathname } = useLocation();

    const selectedVertical: Verticals = useSelector(
        (state: RootStateOrAny) => state.selectedVertical.vertical
    );

    const updateSelectedVertical = (newVertical: Verticals) => {
        if (selectedVertical !== newVertical)
            dispatch(setSelectedVertical(newVertical));
    };

    const sales = SalesHPRoutes.filter((route) => route.isMenuItem);
    const marketing = MarketingHPRoutes.filter((route) => route.isMenuItem);
    const accounting = AccountingRoutes.filter((route) => route.isMenuItem);
    const candidate_routes = SalesCPRoutes.filter((route) => {
        if (!route.isMenuItem) {
            return false;
        }
        if (
            route.props?.view === "fast-tracker" &&
            currentUser.views !== "fast-tracker"
        ) {
            return false;
        }
        return true;
    });
    const urls = pathname.includes("/hp/marketing/")
        ? marketing
        : pathname.includes("/hp/accounting")
          ? accounting
          : pathname.includes("/hp")
            ? sales
            : candidate_routes;

    return (
        <>
            {!isMobileView && (
                <div
                    className="sidebar-wrapper"
                    style={onBoardingStyle(currentUser)}
                >
                    <Drawer
                        variant="permanent"
                        open={isSideBarOpen}
                        sx={{ zIndex: 0 }}
                    >
                        <SideBarHeader
                            isSideBarOpen={isSideBarOpen}
                            currentUser={currentUser}
                            locale={locale}
                        />
                        {currentUser?.views === "hp" && (
                            <VerticalButton
                                isSideBarOpen={isSideBarOpen}
                                selectedVertical={selectedVertical}
                                updateSelectedVertical={updateSelectedVertical}
                                pathname={pathname}
                            />
                        )}
                        <MenuItems
                            urls={urls}
                            currentUser={currentUser}
                            isSideBarOpen={isSideBarOpen}
                            selectedVertical={selectedVertical}
                            messagesNotify={messagesNotify}
                            curatedListCount={curatedListCount}
                            jobApplicants={jobApplicants}
                            locale={locale}
                        />
                        <SideBarFooter
                            isSideBarOpen={isSideBarOpen}
                            setSideBarVisibility={setSideBarVisibility}
                            currentUser={currentUser}
                            locale={locale}
                            changeLang={changeLang}
                        />
                    </Drawer>
                    {FeatureFlag.notification(currentUser.views) && (
                        <NotificationMenu
                            isSideBarOpen={isSideBarOpen}
                            user={currentUser}
                        />
                    )}
                </div>
            )}
        </>
    );
};

export default SideBar;
