import { lazy } from "react";
import { WorkOutlineOutlined, ListOutlined } from "@material-ui/icons";
import { PeopleOutlineOutlined, RouteOutlined } from "@mui/icons-material";
import { RouteInterface } from "@/core/interfaces/route.interface";

// Pages
const Waitlist = lazy(() => import("@/pages/hp/Waitlist"));

const base = "hp/accounting";
const routes: Array<RouteInterface> = [
    {
        path: `${base}/candidates`,
        element: Waitlist,
        isMenuItem: true,
        name: "candidate-list",
        icon: PeopleOutlineOutlined,
        props: {
            open_jobs_endpoint: "job/open-jobs-list/?vertical_id=3",
        },
        isVerticalRequired: false,
    },
    {
        path: `${base}/jobs`,
        element: Waitlist,
        isMenuItem: true,
        name: "jobs",
        icon: WorkOutlineOutlined,
        isVerticalRequired: false,
        props: {
            endpoint: "job/list?vertical_id=3&active=",
        },
    },
    {
        path: `${base}/pipeline`,
        element: Waitlist,
        isMenuItem: true,
        name: "pipeline",
        icon: RouteOutlined,
        isVerticalRequired: false,
        props: {
            open_jobs_endpoint: "job/open-jobs-list/?vertical_id=3",
        },
    },
    {
        path: `${base}/curated_list`,
        element: Waitlist,
        isMenuItem: true,
        name: "curated_list",
        icon: ListOutlined,
        isVerticalRequired: false,
        props: {
            open_jobs_endpoint: "job/open-jobs-list/?vertical_id=3",
        },
    },
    {
        path: `${base}/*`,
        element: Waitlist,
        isVerticalRequired: false,
    },
];

const AccountingRoutes = routes;

export default AccountingRoutes;
