import { createSlice } from "@reduxjs/toolkit";
import singletonLocalStorage from "@/utils/localStoring";
import { createSelector } from "reselect";

const candidateListSlice = createSlice({
    name: "candidateList",
    initialState: {
        candidates: undefined,
        count: 0,
        page: 0,
        nextPageUrl: "",
        prevPageUrl: "",
    },
    reducers: {
        setCandidates: (state, action) => {
            const { vertical, candidates } = action.payload;
            state[vertical] = state[vertical] || {};
            state[vertical].candidates = candidates;
        },
        setCount: (state, action) => {
            const { vertical, count } = action.payload;
            state[vertical] = state[vertical] || {};
            state[vertical].count = count;
        },
        setPage: (state, action) => {
            const { vertical, page } = action.payload;
            state[vertical] = state[vertical] || {};
            state[vertical].page = page;
        },
        setNextPageUrl: (state, action) => {
            const { vertical, nextPageUrl } = action.payload;
            state[vertical] = state[vertical] || {};
            state[vertical].nextPageUrl = nextPageUrl;
        },
        setPrevPageUrl: (state, action) => {
            const { vertical, prevPageUrl } = action.payload;
            state[vertical] = state[vertical] || {};
            state[vertical].prevPageUrl = prevPageUrl;
        },
        resetCandidateList: (state, action) => {
            const { vertical } = action.payload;
            state[vertical] = {
                candidates: undefined,
                count: 0,
                page: 0,
                nextPageUrl: "",
                prevPageUrl: "",
            };
            singletonLocalStorage.removeItem("curr_page");
        },
    },
});

const selectCandidateList = (state) => state.candidateList;
const selectSelectedVertical = (state) => state.selectedVertical.vertical;

export const selectCandidatesByVertical = createSelector(
    [selectCandidateList, selectSelectedVertical],
    (candidateList, selectedVertical) => {
        return (
            candidateList[selectedVertical] || {
                candidates: undefined,
                count: 0,
                page: 0,
                nextPageUrl: "",
                prevPageUrl: "",
            }
        );
    }
);

export const {
    setCandidates,
    setCount,
    setPage,
    setNextPageUrl,
    setPrevPageUrl,
    resetCandidateList,
} = candidateListSlice.actions;

export default candidateListSlice.reducer;
