import { getUser, getSetUser } from "@/contexts/UserContext";
import singletonLocalStorage from "@/utils/localStoring";
import React, { memo, useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";

const RequireAuth: React.FC<{ children: React.ReactNode }> = memo(
    ({ children }) => {
        const location = useLocation();
        const currUser = getUser();
        const setCurrentUser = getSetUser();
        const [user, setUser] = useState(currUser);

        location.search = location.search + location.hash;
        location.hash = "";
        // const token = singletonLocalStorage.getItem("access_token");

        useEffect(() => {
            if (!user) {
                // Check local storage if user is not found in context
                const storedUser = singletonLocalStorage.getItem("user");
                if (storedUser) {
                    setUser(JSON.parse(storedUser));
                    setCurrentUser(JSON.parse(storedUser));
                }
            }
        }, [user]);

        if (!user) {
            // Redirect to login page if no user is found
            return (
                <Navigate
                    to={"/login" + location.search}
                    state={{ from: location }}
                    replace
                />
            );
        }
        if (Object.keys(currUser).length >= 0) {
            return <>{children}</>;
        }
    }
);
RequireAuth.displayName = "RequireAuth";
export default RequireAuth;
