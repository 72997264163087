import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Autocomplete, TextField, FormHelperText, Chip } from "@mui/material";
import { Controller } from "react-hook-form";
import MenuItem from "@mui/material/MenuItem";
import { Stack } from "@mui/system";
import RTL from "../../RTL";
import LocaleContext from "../../contexts/LocaleContext";

function FormSelect(props) {
    const {
        name,
        placeholder,
        control,
        items,
        multi,
        disabled,
        onChange = (functiontodo) => functiontodo,
        isRequired = false,
        idAsValue = false,
        disableClearable = false,
        optionsDisabled = false,
    } = props;
    const { locale } = useContext(LocaleContext);
    const { t } = useTranslation();

    const handleChange = (value, field) => {
        if (multi) {
            field.onChange(value);
        } else {
            if (value !== null) {
                field.onChange(value);
            } else field.onChange(null);
        }
        return value;
    };

    const checkEqual = (option, value) => {
        return option.id == value.id;
    };

    const getValues = (value) => {
        if (!multi) {
            const item = items.find((option) => {
                return option.id === Number(value);
            });
            return item;
        } else {
            const objectArray = [];
            if (value?.length) {
                value.forEach((o) => {
                    const item = items.find((option) => {
                        return option.id === o;
                    });
                    objectArray.push(item);
                });
            }
            return objectArray;
        }
    };

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={multi ? [] : null}
            render={({ field, fieldState }) => {
                return (
                    <Stack gap={0} spacing={0} width={"100%"}>
                        {items && (
                            <Autocomplete
                                classes={{
                                    option: optionsDisabled
                                        ? "autocomplete-option-disabled"
                                        : "",
                                }}
                                options={items}
                                data-testid={`Autocomplete-${name}-select`}
                                disabled={disabled}
                                placeholder={placeholder}
                                disableClearable={disableClearable}
                                multiple={multi}
                                isOptionEqualToValue={(option, value) =>
                                    checkEqual(option, value)
                                }
                                {...field}
                                onChange={(event, value, action) => {
                                    onChange(
                                        handleChange(value, field),
                                        action,
                                        field
                                    );
                                }}
                                required={isRequired}
                                dir={locale == "en" ? "ltr" : "rtl"}
                                noOptionsText={t("empty-options")}
                                getOptionLabel={(option) =>
                                    option[`name_${locale}`]
                                }
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip
                                            {...getTagProps({ index })}
                                            label={option[`name_${locale}`]}
                                            data-testid={`Chip-${name}-option-${index}`}
                                            key={index}
                                        />
                                    ))
                                }
                                ListboxProps={{
                                    "data-testid": `Autocomplete-listbox-${name}-options`,
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        data-testid={`Autocomplete-TextField-${name}`}
                                        placeholder={placeholder}
                                        error={fieldState.invalid}
                                        InputProps={{
                                            ...params.InputProps,
                                            inputProps: {
                                                ...params.inputProps,
                                                "data-testid": `input-${name}`,
                                            },
                                        }}
                                    />
                                )}
                                {...(idAsValue && {
                                    value: getValues(field.value),
                                })}
                            >
                                {items?.map((item, index) => {
                                    return (
                                        <MenuItem
                                            disabled
                                            value={item}
                                            key={index}
                                        >
                                            {item[`name_${locale}`]}
                                        </MenuItem>
                                    );
                                })}
                            </Autocomplete>
                        )}
                        {fieldState.invalid && (
                            <FormHelperText
                                data-testid={`FormHelperText-${name}-required`}
                            >
                                {fieldState.error?.message}
                            </FormHelperText>
                        )}
                    </Stack>
                );
            }}
        />
    );
}

export default RTL(FormSelect);
