import React, { useState } from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import MenuItem from "../../Header/components/menu-item/menu-item";
import MenuItemNotification from "../../Header/components/menu-notification/menu-notification";
import {
    needsToFillPlacement,
    bootcamperNeedToContinue,
    getUserOS,
} from "@/utils/utilFunctions";
import Verticals from "@/core/enums/verticals.enum";
import { Portal } from "@material-ui/core";
import ScalersSnackbar from "@/components/molecules/snackbar/Snackbar";
import { CurrUserDetails } from "@/utils/currUserType";
import { FeatureFlag } from "@/feature-flag";
import useVerticalButton from "./hooks/useVerticalButton";

interface MenuItemsProps {
    urls: Array<{ path: string; name: string; icon: React.ComponentType }>;
    currentUser: CurrUserDetails;
    isSideBarOpen: boolean;
    selectedVertical: Verticals;
    messagesNotify: boolean;
    curatedListCount: number;
    jobApplicants: number;
    locale: string;
}

const MenuItems: React.FC<MenuItemsProps> = ({
    urls,
    currentUser,
    isSideBarOpen,
    selectedVertical,
    messagesNotify,
    curatedListCount,
    jobApplicants,
    locale,
}) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const os = getUserOS();
    const [isDemoSnackbarOpen, setIsDemoSnackbarOpen] = useState(false);
    const { variant } = useVerticalButton(selectedVertical);
    const activeUrls =
        currentUser.name === undefined
            ? urls.filter((route) => route.isPublic)
            : urls;
    return (
        <div
            className={clsx("sidebar", {
                "sidebar--marketing": pathname.includes("/hp/marketing"),
                "sidebar--accounting": pathname.includes("/hp/accounting"),
                "sidebar--client-facing":
                    !pathname.includes("/hp/marketing") &&
                    !pathname.includes("/hp/accounting") &&
                    pathname.includes("/hp"),
                expanded: isSideBarOpen,
            })}
        >
            {activeUrls.map((link, index) => {
                return (
                    <MenuItem
                        key={index}
                        to={
                            currentUser.name === undefined ||
                            needsToFillPlacement(currentUser) ||
                            bootcamperNeedToContinue(currentUser) ||
                            (selectedVertical === Verticals.Marketing &&
                                FeatureFlag?.enable_marketing_v3?.() === false)
                                ? "#"
                                : link.path
                        }
                        text={t(link.name)}
                        variant={variant ?? "default"}
                        active={pathname.includes(link.path)}
                        onClick={(
                            e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
                        ) => {
                            if (
                                (selectedVertical === Verticals.Marketing &&
                                    FeatureFlag?.enable_marketing_v2?.() ===
                                        true &&
                                    FeatureFlag?.enable_marketing_v3?.() ===
                                        false) ||
                                selectedVertical === Verticals.Accounting
                            ) {
                                e.preventDefault();
                                setIsDemoSnackbarOpen(true);
                                return;
                            }
                            if (
                                needsToFillPlacement(currentUser) ||
                                bootcamperNeedToContinue(currentUser)
                            ) {
                                e.preventDefault();
                                document.getElementById(
                                    "you-need-to-complete-profile"
                                )!.style.display = "flex";
                            }
                            if (pathname.includes(link.path)) {
                                e.preventDefault();
                            }
                            if (currentUser.name === undefined) {
                                document.getElementById(
                                    "login-job-modal"
                                )!.style.display = "flex";
                            }
                            return;
                        }}
                        notification={
                            <MenuItemNotification
                                os={os}
                                locale={locale}
                                link={link}
                                messagesNotify={messagesNotify}
                                curatedListCount={curatedListCount}
                                jobApplicants={jobApplicants}
                                isSideBarOpen={isSideBarOpen}
                            />
                        }
                        isSideBarOpen={isSideBarOpen}
                        testId={link.name}
                    >
                        <link.icon fontSize="small" />
                        <Portal>
                            <ScalersSnackbar
                                showing={isDemoSnackbarOpen}
                                handleClose={() => {
                                    setIsDemoSnackbarOpen(false);
                                }}
                                type="info"
                                variant="outlined"
                                hideDuration={4000}
                                relativeHorizontal="center"
                                vertical="top"
                                showIcon={false}
                            >
                                {t("feature_soon")}
                            </ScalersSnackbar>
                        </Portal>
                    </MenuItem>
                );
            })}
        </div>
    );
};

export default MenuItems;
