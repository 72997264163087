import React from "react";
import "./sidebar-button.scss";
import { LoginOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { LightTooltip } from "../../../Header/components/menu-item/menu-item";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";

function SidebarLoginButton({ isSideBarOpen }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <div
            className="expand-collapse-button"
            onClick={() => {
                navigate("/login");
            }}
        >
            <LightTooltip title={t("login.title")} show={!isSideBarOpen}>
                <div
                    className={clsx("sidebar-button", {
                        "sidebar-button-bottom-margin": true,
                    })}
                >
                    <LoginOutlined fontSize="small" />
                    {isSideBarOpen && (
                        <span className="condense">{t("login.title")}</span>
                    )}
                </div>
            </LightTooltip>
        </div>
    );
}

export default SidebarLoginButton;
