export const StatusEnum = {
  AWAITING_SUBMISSION: 1,
  COMPLETE: 2,
  UNDER_REVIEW: 3,
  RESUBMISSION_REQUIRED: 4,
  RESUBMISSION_PICTURE_REQUIRED: 5,
  RESUBMISSION_AND_CV_REQUIRED: 6,
  REJECTED: 7,
  RESUBMISSION_CV_REQUIRED: 8,
  RESUBMISSION_CV_AND_PICTURE: 9,
};

export const StageEnum = {
  REGISTRATION: 1,
  RECORDED_INTERVIEW: 2,
  CANDIDATE_PROFILE: 3,
  MATCH_WITH_COMPANIES: 4,
  ASSESSMENT: 5,
};

export default {
  StatusEnum,
  StageEnum,
};
